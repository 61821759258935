<template>
  <div>
    <user-list/>
  </div>
</template>

<script>
import UserList from '@/components/UserList.vue';

export default {
  components: {
    UserList: UserList,
  },
};
</script>